import { ApolloClient, useQuery, gql, InMemoryCache } from "@apollo/client";
import Settings from "./Settings";
import WeatherData from "./WeatherData";
import Level from "./Level";
import Rain from "./Rain";

const GET_DATA = gql`
        query {
            estacoes {
                nome
                chuva_001h
                chuva_003h
                chuva_006h
                chuva_012h
                chuva_024h
                chuva_048h
                chuva_072h
                chuva_096h
                chuva_120h
                chuva_144h
                chuva_168h
                umidade
                nivel_rio
                nivel_rio_historico {
                    nivel
                    t_stamp
                }
                temp_atual
                temp_sens
                tipo
            }
        }
    `;

export default class DataProvider {
    async getData(): Promise<WeatherData[]> {
        const client = new ApolloClient({
            uri: 'https://monitoramento.defesacivil.sc.gov.br/graphql',
            cache: new InMemoryCache()
        });
        const { loading, error, data } = await client.query({
            query: GET_DATA
        });
        const mappedData = this.mapData(data);

        localStorage.setItem('lastUpdate', Math.floor(new Date().getTime() / 1000).toString());

        const selectedCities = Settings.getSavedCities();
        let dataBlumenau: any = null;
        if (selectedCities.includes("Blumenau")) {
            try {
                const alertaBluResponse = await fetch('https://juw5k0e325.execute-api.us-east-1.amazonaws.com/default/alertablu-proxy-function');
                dataBlumenau = await alertaBluResponse.json();
            } catch {
                console.log('Failed to fetch data for Blumenau');
            }
        }

        return Array.from(mappedData.entries())
            .filter(([key, value]) => value.tipo !== 'Barragem')
            .sort((key1, key2) => key1[0].localeCompare(key2[0]))
            .map(([key, value]) => {
                let riverLevel = value.nivel_rio;
                let levelHistory = value.nivel_rio_historico ? value.nivel_rio_historico.map((h: any) => new Level(h.nivel, new Date(h.t_stamp))) : [];
                if (key === 'Blumenau' && dataBlumenau && dataBlumenau.niveis) {
                    riverLevel = dataBlumenau.niveis[dataBlumenau.niveis.length - 1].nivel;
                    levelHistory = dataBlumenau.niveis.map((n: any) => new Level(n.nivel, new Date(n.horaLeitura)));
                }
                const rainVolumes = this.extractRainVolumes(value);
                const weatherData = new WeatherData(key, value.chuva_001h, value.umidade, riverLevel, value.temp_atual, value.temp_sens);
                weatherData.setRiverLevels(levelHistory);
                weatherData.setRainVolumes(rainVolumes);
                return weatherData;
            });
    }

    private extractRainVolumes(data: any) : Rain[] {
        const rainVolumes : Rain[] = [];
        rainVolumes.push(new Rain(data.chuva_072h, 72));
        rainVolumes.push(new Rain(data.chuva_048h, 48));
        rainVolumes.push(new Rain(data.chuva_024h, 24));
        rainVolumes.push(new Rain(data.chuva_012h, 12));
        rainVolumes.push(new Rain(data.chuva_006h, 6));
        rainVolumes.push(new Rain(data.chuva_001h, 1));
        return rainVolumes;
    }

    private mapData(data: any): Map<string, any> {
        const dataMap = new Map<string, any>();
        data.estacoes.forEach((obj: any) => {
            const regex = new RegExp('SDC-SC\\s([^\\d]+)\\s?\\d?', 'g');
            const results = regex.exec(obj.nome);
            const name = results ? results[1].trim() : '';
            if (results && !dataMap.has(name)) {
                dataMap.set(name, obj);
            } else {
                const allProps = dataMap.get(name);
                const newProps = Object.assign({}, allProps);
                if (obj.chuva_001h) {
                    newProps.chuva_001h = obj.chuva_001h;
                }
                if (obj.umidade) {
                    newProps.umidade = obj.umidade;
                }
                if (obj.nivel_rio) {
                    newProps.nivel_rio = obj.nivel_rio;
                }
                if (obj.temp_atual) {
                    newProps.temp_atual = obj.temp_atual;
                }
                if (obj.temp_sens) {
                    newProps.temp_sens = obj.temp_sens;
                }
                if (obj.nivel_rio_historico && obj.nivel_rio_historico[0].nivel) {
                    newProps.nivel_rio_historico = obj.nivel_rio_historico;
                }
                dataMap.set(name, newProps);
            }
        });
        return dataMap;
    }

}
