import React from 'react';
import WeatherData from '../../core/WeatherData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudRain, faDroplet, faTemperatureQuarter, faWater, faUpLong, faDownLong, faStar, faChartArea, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { FlexibleWidthXYPlot, VerticalBarSeries, YAxis, XAxis, AreaSeries, AreaSeriesPoint, VerticalBarSeriesPoint } from 'react-vis';
import "react-vis/dist/style.css";
import './CityCard.css';


interface CityCard {
    // Define your component's props here
    weatherData: WeatherData,
    isFavorite: boolean,
    favoriteCallback: any
}

interface CityCardComponentState {
    showCurrent: boolean
    riverChart: boolean
}

export default class CityCardComponent extends React.Component<CityCard, CityCardComponentState> {
    constructor(props: CityCard) {
        super(props);

        this.state = {
            showCurrent: true,
            riverChart: true
        };
    }

    toggleFavorite = () => {
        this.props.favoriteCallback(this.props.weatherData.getName(), !this.props.isFavorite);
    }

    toggleCurrentView = () => {
        this.setState({ showCurrent: !this.state.showCurrent });
    }

    showRiverChart = () => {
        this.setState({ riverChart: true });
    }

    showRainChart = () => {
        this.setState({ riverChart: false });
    }

    render() {
        const rain = this.props.weatherData.getCurrentRain();
        const humidity = this.props.weatherData.getHumidity();
        const riverLevel = this.props.weatherData.getRiverLevel();
        const temperature = this.props.weatherData.getTemperature();
        const temperatureFeel = this.props.weatherData.getTemperatureFeel();
        let levelChange = this.props.weatherData.getLevelDifference();
        let levelDiff;
        if (levelChange !== null) {
            const levelIcon = levelChange > 0 ? <FontAwesomeIcon color='red' icon={faUpLong}></FontAwesomeIcon> : <FontAwesomeIcon color='green' icon={faDownLong}></FontAwesomeIcon>;
            levelDiff = <div className="level-diff">{levelIcon}<span>{levelChange.toFixed(2)} m</span></div>
        } else {
            levelDiff = <span />
        }
        const displayIcon = this.state.showCurrent ? faChartArea : faTableColumns;
        const icon = this.props.isFavorite ? faStar : farStar;

        let data;
        if (this.state.showCurrent) {
            data = <div><div className="metrics columns is-mobile">
                <div className="column is-half metric rain-container">
                    <div className="is-flex is-flex-direction-column">
                        <FontAwesomeIcon color='gray' size='2x' icon={faCloudRain} /> {/* Use the FontAwesomeIcon component */}
                        <span>{typeof rain == 'number' ? rain.toFixed(1) : '-'} mm</span>
                    </div>
                </div>
                <div className="column is-half metric humidity-container">
                    <div className="is-flex is-flex-direction-column">
                        <FontAwesomeIcon color='blue' size='2x' icon={faDroplet} /> {/* Use the FontAwesomeIcon component */}
                        <span>{humidity ? (humidity * 100).toFixed(0) : '-'} %</span>
                    </div>
                </div>
            </div>
                <div className="metrics columns is-mobile">
                    <div className="column is-half metric temperature-container">
                        <div className="is-flex is-flex-direction-column">
                            <FontAwesomeIcon color='orange' size='2x' icon={faTemperatureQuarter} /> {/* Use the FontAwesomeIcon component */}
                            <span>{typeof temperature == 'number' ? temperature.toFixed(1) : '-'} ºC</span>
                            <span className="is-size-7">{typeof temperatureFeel == 'number' ? temperatureFeel.toFixed(1) : '-'} ºC</span>
                            <span className="is-size-7">(sensação)</span>
                        </div>
                    </div>
                    <div className="column is-half metric river-container">
                        <div className="is-flex is-flex-direction-column">
                            <FontAwesomeIcon color='lightblue' size='2x' icon={faWater} /> {/* Use the FontAwesomeIcon component */}
                            <span>{typeof riverLevel == 'number' ? riverLevel.toFixed(2) : '-'} m</span>
                            {levelDiff}
                        </div>
                    </div>
                </div></div>;
        } else {
            const riverClass = this.state.riverChart ? 'is-link is-light is-active' : '';
            const rainClass = this.state.riverChart ? '' : 'is-link is-light is-active';
            const chartTypeToggle =
                <div className="chart-type">
                    <button className={`button is-small ${riverClass}`} onClick={this.showRiverChart}><FontAwesomeIcon color='blue' icon={faWater} /></button>
                    <button className={`button is-small ${rainClass}`} onClick={this.showRainChart}><FontAwesomeIcon color='gray' icon={faCloudRain} /></button>
                </div>;
            if (this.state.riverChart) {
                const levels = this.props.weatherData.getRiverLevels();     
                const chartData = levels.map((l, i): AreaSeriesPoint => { return { x: i, y: l.getLevel() }; });
                data = <div>
                    <div className='chart-title'><FontAwesomeIcon color='lightblue' icon={faWater} /><span>Nível do Rio</span></div>
                    <FlexibleWidthXYPlot height={152} >
                        <XAxis tickSizeInner={0} tickTotal={6} tickLabelAngle={-20} tickFormat={(v): string => {
                            const d = levels[v].getDate();
                            return `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
                        }}></XAxis>
                        <YAxis />
                        <AreaSeries
                            className="area-series-example"
                            curve="curveNatural"
                            data={chartData}
                            fill={'hsl(229, 53%, 53%)'}
                            stroke={'hsl(229, 53%, 53%)'}
                        />
                    </FlexibleWidthXYPlot>
                    {chartTypeToggle}
                </div>;
            } else {
                const volumes = this.props.weatherData.getRainVolumes();
                const chartData = volumes.map((l, i): VerticalBarSeriesPoint => { return { x: `${l.getPeriod()}h`, y: l.getVolume() }; });
                data = <div>
                <div className='chart-title'><FontAwesomeIcon color='gray' icon={faCloudRain} /><span>Chuva (mm)</span></div>
                <FlexibleWidthXYPlot height={152} xType='ordinal'  >
                    <XAxis tickSizeInner={0}></XAxis>
                    <YAxis />
                    <VerticalBarSeries
                        fill={'darkBlue'}
                        stroke={'darkBlue'}
                        data={chartData}
                        barWidth={0.5}
                    />
                </FlexibleWidthXYPlot>
                {chartTypeToggle}
            </div>;
            }
        }

        return (
            <div className="column is-one-third">
                <div className="box">
                    <div className="city-header">
                        <FontAwesomeIcon color='darkSlateBlue' className="toggle-current" icon={displayIcon} onClick={this.toggleCurrentView}></FontAwesomeIcon>
                        <div className="city-name is-size-4">{this.props.weatherData.getName()}</div>
                        <FontAwesomeIcon color='gold' className="toggle-favorite" icon={icon} onClick={this.toggleFavorite}></FontAwesomeIcon>
                    </div>
                    {data}
                </div>
            </div>
        );
    }
}
