export default class Rain {
    volume: number;
    period: number;

    constructor(volume: number, period: number) {
        this.volume = volume;
        this.period = period;
    }

    getVolume(): number {
        return this.volume;
    }

    getPeriod(): number {
        return this.period;
    }
}
